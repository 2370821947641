import i1 from "./1.jpg";
import i2 from "./2.jpg";
import i3 from "./3.jpg";
import i4 from "./4.jpg";
import i5 from "./5.jpg";
import i6 from "./6.jpg";
import i7 from "./7.jpg";
import i8 from "./8.jpg";
import i9 from "./9.jpg";
import i10 from "./10.jpg";
import i11 from "./11.jpg";
import i12 from "./12.jpg";
import i13 from "./13.jpg";
import i14 from "./14.jpg";
import i15 from "./15.jpg";
import i16 from "./16.jpg";
import i17 from "./17.jpg";
import i18 from "./18.jpg";

export default [
  i1,
  i2,
  i3,
  i4,
  i5,
  i6,
  i7,
  i8,
  i9,
  i10,
  i11,
  i12,
  i13,
  i14,
  i15,
  i16,
  i17,
  i18
];